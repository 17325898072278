import moment from 'moment-timezone';
import numeral from 'numeral';

export const nanToZero = (v: number) => (isNaN(v) ? 0 : v);
export const normalizeSmallValue = (v: number) => nanToZero(v < 0.00001 ? 0 : v);
export const percent = (portion: number, sum: number) => (sum === 0 ? 1 : portion / sum);

export const formatNumber = (val: number | string) => numeral(val).format('0.00a');
export const formatNumberRounded = (val: number) =>
  numeral(Math.round(val)).format('0[.]a');

export const formatPercent = (percent: number) =>
  isFinite(percent) ? numeral(normalizeSmallValue(percent)).format('0[.]00%') : '∞';
export const formatPercent1Decimal = (percent: number) =>
  isFinite(percent) ? numeral(normalizeSmallValue(percent)).format('0[.]0%') : '∞';

export const formatGigsToBytes = (gigs: number, decimals = 2, spacer = ' ', k = 1000) => {
  const bytes = gigs * k * k * k;
  if (bytes === 0) return '0 GB';
  const dm = decimals,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + spacer + sizes[i];
};

export const formatBytes = (bytes: number, decimals = 2, spacer = ' ', k = 1000) => {
  if (bytes === 0) return '0 GB';
  const dm = decimals,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + spacer + sizes[i];
};

export const formatKiloBitsPerSecond = (val: number, decimals = 2, spacer = '') => {
  if (val < 1000) return `${val.toFixed(decimals)}${spacer}Kbps`;
  val = val / 1000;

  if (val < 1000) return `${val.toFixed(decimals)}${spacer}Mbps`;
  val = val / 1000;

  if (val < 1000) return `${val.toFixed(decimals)}${spacer}Gbps`;
  val = val / 1000;

  return `${val.toFixed(decimals)}${spacer}Tbps`;
};

export const formatTime = (time: number | string | Date, format = 'YYYY-MM-DD') =>
  moment(time).format(format);

export const formatTimeToUtc = (time: number | string | Date, format = 'YYYY-MM-DD') =>
  moment.tz(time, 'UTC').format(format);

export const formatTimeToPst = (time: number | string | Date, format = 'YYYY-MM-DD') =>
  moment.tz(time, 'America/Los_Angeles').format(format);

export const clamp = (a: number, min: number, max: number) => {
  if (a < min) return min;
  if (a > max) return max;
  return a;
};

export const formatSerial = (serial: string) => {
  let out = '';
  for (let i = 0; i < serial.length; i += 4) {
    if (i > 0) {
      out = out + ' ';
    }
    out = out + serial.substring(i, i + 4).toUpperCase();
  }
  return out;
};

export const formatUSDCurrency = (() => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (value: number) => {
    return formatter.format(value);
  };
})();

export const pad = (value: number) => (value < 10 ? `0${value}` : value);
export const formatHour = (hod = 0) => `${pad(hod)}:00 - ${pad(hod + 1)}:00`;

export function numberRange(start: number, end: number) {
  return new Array(end - start).fill(0).map((d, i) => i + start);
}

export function maxBy<T extends object>(arr: T[], field: keyof T) {
  if (!arr || arr.length === 0) return undefined;
  const result = arr.reduce((prev, next) => (next[field] > prev[field] ? next : prev));
  if (field in result) return result;
  return undefined;
}

export function minBy<T extends object>(arr: T[], field: keyof T) {
  if (!arr || arr.length === 0) return undefined;
  const result = arr.reduce((prev, next) => (next[field] < prev[field] ? next : prev));
  if (field in result) return result;
  return undefined;
}

export function sumBy<T extends PropertyKey>(arr: { [K in T]: number }[], field: T) {
  if (!arr || arr.length === 0) return 0;
  const result = arr.reduce((acc, cur) => {
    acc += cur[field] || 0;
    return acc;
  }, 0);
  return result;
}

export function groupBy<T extends object>(
  arr: T[],
  criteria: keyof T | ((value: T) => T[keyof T]),
) {
  return arr.reduce(function (obj, item) {
    let key = (
      typeof criteria === 'function' ? criteria(item) : item[criteria]
    ) as PropertyKey;

    if (!key) return obj;
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = [];
    }
    obj[key].push(item);

    return obj;
  }, {} as Record<PropertyKey, T[]>);
}

export function isInViewport(element?: HTMLElement | null) {
  if (!element) return true;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const setDelay = (value: number) => {
  let newValue = value;
  if (value === 1) {
    newValue = 3;
  } else if (value === 3) {
    newValue = 5;
  } else if (value === 5) {
    newValue = 10;
  } else if (value === 10) {
    newValue = 30;
  }
  return newValue;
};

export function convertDateToUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}
