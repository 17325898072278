import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  AUTH_IMPERSONATE,
  AUTH_SIGNIN_USER,
  AUTH_SIGNIN_USER_FROM_PORTAL,
  AUTH_SIGNIN_USER_SUCCESS,
  AUTH_SIGNOUT_USER,
} from './constants';
import {
  authImpersonateFail,
  authImpersonateSuccess,
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from './reducer';
import request, { cleanCache } from 'utils/request';
import { toast } from 'react-toastify';
import { dropState } from '../services';
import { signOutFromAccount } from '../account';

const doSignIn = function* ({ payload }) {
  const requestURL = `${process.env.REACT_APP_NODEAPI_BASE_URL}/auth/login`;
  const params = {
    method: 'POST',
    data: payload,
  };

  try {
    const { user, token } = yield call(request, requestURL, params);
    sessionStorage.setItem('auth', JSON.stringify({ user, token }));
    yield put(userSignInSuccess({ user, token }));
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
};

const doSignInFromPortal = function* ({ payload }) {
  const { token } = payload;
  const requestURL = `${process.env.REACT_APP_NODEAPI_BASE_URL}/auth/autoLogin/${token}`;
  const params = {
    method: 'POST',
  };

  try {
    const { user, token } = yield call(request, requestURL, params);
    sessionStorage.setItem('auth', JSON.stringify({ user, token }));
    yield put(userSignInSuccess(user));
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
};

const doLoginAs = function* ({ payload }) {
  const requestURL = `${process.env.REACT_APP_NODEAPI_BASE_URL}/auth/loginAs/${payload.username}`;
  const params = {
    method: 'POST',
    data: {},
  };

  try {
    const { user, token } = yield call(request, requestURL, params);
    sessionStorage.setItem('auth', JSON.stringify({ user, token }));
    // TODO: Store old session data
    yield put(authImpersonateSuccess({ user, token }));
    yield put(userSignInSuccess({ user, token }));
  } catch (error) {
    toast.error('Failed to impersonate user');
    yield put(authImpersonateFail(error.message));
    yield put(showAuthMessage(error.message));
  }
};

// eslint-disable-next-line
const doSuccessLogin = function* () {
  // window.location = '/app/analytics';
};

const doSignOut = function* ({ payload }) {
  try {
    yield put(dropState());
    sessionStorage.removeItem('auth');
    cleanCache(true);
    yield put(signOutFromAccount());
    yield put(userSignOutSuccess());
  } catch (error) {
    yield put(showAuthMessage(error));
  }
};

export const saga = function* () {
  yield takeEvery(AUTH_SIGNIN_USER, doSignIn);
  yield takeEvery(AUTH_SIGNIN_USER_FROM_PORTAL, doSignInFromPortal);
  yield takeEvery(AUTH_SIGNOUT_USER, doSignOut);
  yield takeEvery(AUTH_IMPERSONATE, doLoginAs);
  yield takeLatest(AUTH_SIGNIN_USER_SUCCESS, doSuccessLogin);
};
